import { FC } from "react";
import { Icon } from "./icon";

// above-the-fold-icons:
import IconChevron from "./icons/IconChevron";
import IconLogo from "./icons/IconLogo";
import IconSearch from "./icons/IconSearch";
import IconPhone from "./icons/IconPhone";
import IconInterestBadge from "./icons/IconInterestBadge";

const Icons = {
  "add-user": "IconAddUser",
  airplane: "IconAirplane",
  arrow: "IconArrow",
  AstonMartinCi: "IconAstonMartinCi",
  attention: "IconAttention",
  award: "IconAward",
  bell: "IconBell",
  BentleyCi: "IconBentleyCi",
  business: "IconBusiness",
  calculator: "IconCalculator",
  calendar: "IconCalendar",
  camera: "IconCamera",
  checkmark: "IconCheckmark",
  "checkmark-complex": "IconCheckmarkComplex",
  chevron: "IconChevron",
  clock: "IconClock",
  clockInvert: "IconClockInvert",
  close: "IconClose",
  cocktail: "IconCocktail",
  coffee: "IconCoffee",
  comfort: "IconComfort",
  conversation: "IconConversation",
  cookie: "IconCookie",
  copy: "IconCopy",
  dark: "IconDark",
  delivery: "IconDelivery",
  download: "IconDownload",
  edit: "IconEdit",
  education: "IconEducation",
  electric: "IconElectro",
  energy: "IconEnergy",
  euroHouse: "IconEuroHouse",
  exchange: "IconExchange",
  facebook: "IconFacebook",
  facebookCD: "IconFacebookCD",
  FerrariCi: "IconFerrariCi",
  file: "IconFile",
  "file-add": "IconFileAdd",
  FordGtCi: "IconFordGtCi",
  "form-upload": "IconFormUpload",
  formular: "IconFormular",
  fuel: "IconFuel",
  gallery: "IconGallery",
  galleryDetail: "IconGalleryDetail",
  google: "IconGoogle",
  growth: "IconGrowth",
  heart: "IconHeart",
  "heart-beat": "IconHeartBeat",
  "heart-fill": "IconPrintFill",
  hybrid: "IconHybrid",
  info: "IconInfo",
  interestBadge: "IconInterestBadge",
  label: "IconLabel",
  LamborghiniCi: "IconLamborghiniCi",
  LandRoverCi: "IconLandRoverCi",
  leasing: "IconLeasing",
  length: "IconLength",
  light: "IconLight",
  like: "IconLike",
  "lineend-skewed-left": "IconLineendSkewedLeft",
  linkedIn: "IconLinkedIn",
  linkedInCD: "IconLinkedInCD",
  list: "IconList",
  location: "IconLocation",
  logo: "IconLogo",
  logoCareer: "IconLogoCareer",
  "logo-dm": "IconLogoDm",
  mail: "IconMail",
  mailCD: "IconMailCD",
  menu: "IconMenu",
  minus: "IconMinus",
  money: "IconMoney",
  monitor: "IconMonitor",
  moon: "IconMoon",
  one: "IconOne",
  "open-book": "IconOpenBook",
  parking: "IconParking",
  pause: "IconPause",
  pen: "IconPen",
  "pen-underline": "IconPenUnderline",
  percentage: "IconPercentage",
  phone: "IconPhone",
  photo: "IconPhoto",
  "pick-up": "IconPickUp",
  play: "IconPlay",
  playFull: "IconPlayFull",
  plus: "IconPlus",
  price: "IconPrice",
  print: "IconPrint",
  private: "IconPrivate",
  radio: "IconRadio",
  rating: "IconRating",
  "repair-tool": "IconRepairTool",
  request: "IconRequest",
  reset: "IconReset",
  safety: "IconSafety",
  search: "IconSearch",
  seat: "IconSeat",
  send: "IconSend",
  share: "IconShare",
  "share-arrow": "IconShareArrow",
  "shopping-cart": "IconShoppingCart",
  "smile-face": "IconSmileFace",
  sort: "IconSort",
  speaker: "IconSpeaker",
  "speaker-full": "IconSpeakerFull",
  "speaker-little": "IconSpeakerLittle",
  "speaker-no": "IconSpeakerNo",
  speed: "IconSpeed",
  sport: "IconSport",
  "ssl-lock": "IconSslLock",
  car: "IconStModel",
  star: "IconStar",
  "star-filled": "IconStarFilled",
  stopper: "IconStopper",
  sun: "IconSun",
  suv: "IconSuv",
  telegram: "IconTelegram",
  temperature: "IconTemperature",
  "thumb-up": "IconThumbUp",
  tiles: "IconTiles",
  trade: "IconTrade",
  transporter: "IconTransporter",
  trash: "IconTrash",
  users: "IconUsers",
  way: "IconWay",
  weight: "IconWeight",
  whatsapp: "IconWhatsapp",
  whatsappCD: "IconWhatsappCD",
  winter: "IconWinter",
  zoom: "IconZoom",
};

/**
 * IconMap
 *
 * **Hove to add new icons:**
 *
 * 1. duplicate an existing Icon-file and adjust its contents
 * 2. add a new entry to the `Icons`-object
 *     * `[key]: [value]`
 *     * where `key` is the component's DISPLAYNAME, and `value` the component's FILENAME (without ".tsx")
 * 3. for normal icons, that's it!
 *
 * **for ABOVE-THE-FOLD-icons:**
 *
 * These icons are loaded staticaly, as a dynamic import would result in a small visual glitch on the page when the icon is loaded.
 * To prevent this, these icons are importet directly in the source-files.
 *
 * 1. import the Icon-module in the head of this file
 * 2. add a new `switch-case` entry in the `IconMap`-function
 */
const IconMap = Object.keys(Icons).reduce((map, iconKey) => {
  let iconModule = null as FC<Icon> | null;

  // above-the-fold-icons:
  switch (iconKey) {
    case "chevron":
      iconModule = IconChevron;
      break;
    case "logo":
      iconModule = IconLogo;
      break;
    case "search":
      iconModule = IconSearch;
      break;
    case "phone":
      iconModule = IconPhone;
      break;
    case "interestBadge":
      iconModule = IconInterestBadge;
      break;
    default:
      break;
  }

  map[iconKey] = {
    name: Icons[iconKey],
    module: iconModule,
  };

  return map;
}, {});

export default IconMap;
