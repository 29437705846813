import { Icon } from "@/components/Icon";
import { Fragment } from "react";

const UspBarStatic = ({ className = "", isFooter = false }) => {
  const uspbarItems = [
    { title: "deutschlandweit kostenlose Lieferung", icon: "checkmark" },
    { title: "Europas größter Transporter Discounter", icon: "checkmark" },
    { title: "10 Jahre Kögler Garantie", icon: "checkmark" },
    { title: "4,99% Topzins", icon: "checkmark" },
  ];
  return (
    <Fragment>
      <div
        className={`w-full h-full bg-blue-dark text-white mt-10 ${className}`}
      >
        <div className="container flex lg:flex-row flex-col lg:justify-around justify-start py-7 select-none lg:gap-y-0 gap-y-8">
          {uspbarItems.map((item, index) => {
            return (
              <div
                key={index}
                className="flex lg:flex-col flex-row lg:items-center items-start gap-y-6"
              >
                <Icon
                  type={item.icon}
                  className="text-success w-7 h-7 lg:w-8 lg:h-8 lg:mr-0 mr-4"
                />
                <p className="font-bold max-w-[250px] text-center lg:text-2xl text-base lg:whitespace-normal whitespace-nowrap">
                  {item.title}
                </p>
              </div>
            );
          })}
        </div>
      </div>
      {isFooter && <div className="w-full h-14 bg-white"></div>}
    </Fragment>
  );
};

export default UspBarStatic;
