import { Icon } from "@/components/Icon";
import IconsEnum from "enums/icons";
import Link from "@/components/Link/Link";
import { cva } from "class-variance-authority";
import { Fragment } from "react";

const uspStyles = cva("container mx-auto grid gap-4", {
  variants: {
    rows: {
      0: "grid-cols-1 sm:grid-cols-3",
      1: "grid-rows-1 lg:grid-flow-col auto-cols-auto",
      2: "grid-rows-2 lg:grid-flow-col auto-cols-auto",
      3: "grid-rows-3 grid-cols-1 sm:grid-cols-4",
      default: "grid-cols-1 sm:grid-cols-3",
      'none': "",
    },
    layout: {
      "layout-2": "justify-start flex-col xl:w-[50%] xl:mx-auto",
      "layout-1": "justify-start flex-col lg:grid-cols-2 xl:w-[50%] xl:mx-auto",
      default: "justify-center md:justify-around md:justify-items-center",
    },
  },
  defaultVariants: {
    rows: "default",
    layout: "default",
  },
});
const uspItemStyles = cva("", {
  variants: {
    items: {
      "layout-2": "flex justify-start items-center",
      "layout-1": "flex justify-start items-center",
      default: "md:py-0 py-2 flex-1 flex-wrap my-6 text-center text-white w-auto px-4 lg:max-w-[270px]",
    },
    svg: {
      "layout-2": "w-[35px] mb-4 text-orange-medium mr-5 shrink-0",
      "layout-1": "w-[35px] mb-4 text-orange-medium mr-5 shrink-0",
      default: "w-[35px] mx-auto mb-4 text-green-light",
    },
  },
  defaultVariants: {
    items: "default",
  },
});

const UspBar = ({
  items = [],
  title = "",
  rows,
  layout,
  removeVerticalMargin = false,
}) => {
  return (
    <div
      className={`bg-blue-dark  py-10 md:py-11 lg:py-12 ${
        removeVerticalMargin ? "" : "lg:mb-16 mb-9"
      }`}
    >
      {title && (
        <div className="container text-center">
          <p className="text-white font-bold text-4xl lg:text-5xl mb-8 lg:mb-9">
            {title}
          </p>
        </div>
      )}
      <div className="container mx-auto">
        <div className={`${uspStyles({ rows, layout })}`}>
          {items.map(({ header, subheader, url, icon }, index) => {
            return (
              <Fragment key={`usp-link-icon-${index}`}>
                {url ? (
                  <Link href={url} passHref legacyBehavior>
                    <a
                      className={`${uspItemStyles({
                        items: layout,
                      })} hover:scale-110 group  `}
                    >
                      <Icon
                        type={IconsEnum[icon]}
                        className={`${uspItemStyles({
                          svg: layout,
                          items: null,
                        })} `}
                      />
                      <p className="font-bold mb-4 ">{header}</p>

                      {subheader && <p>{subheader}</p>}
                    </a>
                  </Link>
                ) : (
                  <div
                    className={`${uspItemStyles({ items: layout })} select-none `}
                    key={`usp-${index + icon}`}
                  >
                    <Icon
                      type={IconsEnum[icon]}
                      className={`${uspItemStyles({
                        svg: layout,
                        items: null,
                      })} `}
                    />
                    <p className="font-bold mb-4">{header}</p>

                    {subheader && <p>{subheader}</p>}
                  </div>
                )}
              </Fragment>
            );
          })}
        </div>
      </div>
    </div>
  );
};
const UspBarLayout1 = ({ items = [], title = "", rows, layout }) => {
  return (
    <div className="bg-gray-100  py-10 md:py-11 lg:py-8">
      {title && (
        <div className="container text-center">
          <p className="text-blue-dark font-bold text-4xl lg:text-5xl mb-8 lg:mb-9">
            {title}
          </p>
        </div>
      )}
      <div className="container mx-auto">
        <div className={`${uspStyles({ rows, layout })}`}>
          {items.map(({ header, subheader, url, icon }, index) => (
            <div
              className={`${uspItemStyles({ items: layout })}`}
              key={`usp-item-${index + icon}`}
            >
              <Icon
                type={IconsEnum[icon]}
                className={`${uspItemStyles({ svg: layout, items: null })} `}
              />
              <p className="font-bold mb-4">{header}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default UspBar;

export const Typo3UspBar = ({ content, appearance }) => {
  const { item, header, rows } = content;
  const { layout, remove_vertical_margin = false } = appearance;

  switch (layout) {
    case "layout-1":
    case "layout-2":
      return (
        <UspBarLayout1
          items={item}
          title={header}
          rows={'none'}
          layout={layout}
        />
      );
    default:
      return (
        <UspBar
          items={item}
          title={header}
          rows={rows}
          layout={layout}
          removeVerticalMargin={remove_vertical_margin}
        />
      );
  }
};
