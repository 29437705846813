import { Button } from "@/components/Button/Button";
import Gallery from "@/components/Gallery/Gallery";
import { FC } from "react";
import Headlines from "../Headlines/Headlines";
import Text from "../Text/Text";
import { getColPosContainer } from "utils/get-col-pos-container";

interface PositionI {
  horizontal: "left" | "right";
}
interface GalleryI {
  position: PositionI;
}
interface ContentI {
  gallery: GalleryI;
  bodytext: string;
  header: string;
  subheader: string;
  headerLink: HeaderLinkI;
}

interface HeaderLinkI {
  href: string;
  target?: string;
  class?: string;
  title: string;
  linkText?: string;
  additionalAttributes?: any[];
}

const TextImage: FC<{ content: ContentI; id: string; appearance?: any; colPos?: number }> = (
  { content, id, appearance, colPos }
) => {
  const { gallery, headerLink } = content;
  const { position } = gallery;

  const isLeft = position.horizontal === "left";
  const isRight = position.horizontal === "right";

  return (
    <div className="@container" id={`c${id}`}>
      <div
        className={`${getColPosContainer(colPos)} w-full grid grid-cols-1 md:grid-cols-2 @3xl:!grid-cols-2 relative`}
      >
        {isLeft && <ImageCol gallery={gallery} />}
        <div
          className={`${
            isLeft ? "@3xl:!pl-16 md:pl-16" : "md:pr-16 @3xl:!pr-16"
          } py-6 flex flex-col justify-center h-full order-2 md:order-none @3xl:!order-none `}
        >
          <Text content={content} noPadding={true} />
          <OptionalButton {...headerLink} />
        </div>
        {isRight && <ImageCol gallery={gallery} />}
      </div>
    </div>
  );
};

const ImageCol = ({ gallery }) => (
  <div className="flex flex-col justify-center h-full order-1 md:order-none @3xl:!order-none">
    <Gallery {...gallery} />
  </div>
);
const OptionalButton = ({ href, title }) => {
  return (
    <>
      {href && title && (
        <div className="lg:container flex justify-start items-center lg:mt-9 mt-7 lg:mb-0 mb-6">
          <Button as="a" href={href} className="lg:w-auto w-full">
            {title}
          </Button>
        </div>
      )}
    </>
  );
};

export default TextImage;
