import Collapsible from "@/components/Collapsible/Collapsible";
import { FC, useState } from "react";
import { Slot } from "utils/create-slots";
import { getColPosContainer } from "utils/get-col-pos-container";
import Headlines from "../Headlines/Headlines";
import HTML from "../HTML/HTML";
import s from "./Text.module.css";

const Text: FC<{ content: any; noPadding?: boolean; id?: string; colPos?: number }> = (
  { content, noPadding = false, id = "", colPos }
) => {
  const removePadding =
    noPadding || colPos === 201 || colPos === 202 || colPos === 203;

  const isHeadlineNested = removePadding || false;
  const [open, setOpen] = useState(false);
  return (
    <div
      className={`${getColPosContainer(colPos)} ${
        removePadding ? "" : "lg:py-10 py-8 "
      }`}
      id={`c${id}`}
    >
      <Headlines
        className={`${
          isHeadlineNested ? "mb-6 mt-2" : "lg:mb-8 lg:mt-3 mb-7 mt-4 "
        }`}
        {...content}
      />
      <div className={`${s.Content}`}>
        <HTML content={content.bodytext} />
      </div>
      {content.collapsibleBodytext && (
        <Collapsible onOpenChange={setOpen} variant="mobile">
          <Slot name="trigger">
            <Collapsible.Trigger
              className={`block lg:hidden text-blue-light hover:text-blue-medium underline py-8 w-full`}
            >
              {open ? "weniger lesen" : "mehr lesen"}
            </Collapsible.Trigger>
          </Slot>
          <Slot name="content">
            <div className={`${s.Content}`}>
              <HTML content={content.collapsibleBodytext} />
            </div>
          </Slot>
        </Collapsible>
      )}
    </div>
  );
};

export default Object.assign(Text);
// TODO remove via BE in api for nonBindingrequestForm
export const CarHeading = (props) => {
  const { properties, renderingOptions, label = "", defaultValue } = props;
  const { labelPrefix = "", labelSeparator = "" } = renderingOptions;
  const { text } = properties;
  return (
    <Text
      content={{
        header: `${labelPrefix}${labelSeparator}${label}${defaultValue}`,
        bodytext: text,
      }}
    />
  );
};
