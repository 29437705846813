import Image, { getImageProps } from "next/image";
import { FC } from "react";

const ArtDirectionImage: FC<{
  image: string;
  imageTablet?: string;
  imageMobile?: string;
  width?: number;
  height?: number;
  sizes?: string;
  fill?: boolean;
  priority?: boolean;
  alt: string;
  className?: string;
}> = ({
  image,
  imageTablet,
  imageMobile,
  fill = true,
  sizes = "100vw",
  priority = false,
  alt,
  className = "",
}) => {
  if (!image && !imageMobile && !imageTablet) return null;
  /**
   * Due to the priority-flag (when set to `true`), the default image is always
   * loaded, independent of the source specified by the source-tags. To reduce
   * the impact on mobile devices, we switched the order. If a mobile image is
   * provided, it is used as default. First fallback is tablet, overall fallback
   * is the large desktop-image (the `image`-attribute is always provided).
   * @see FORDK-3361
   */

  let imageDefault: string;
  if (imageMobile) {
    imageDefault = imageMobile;
  } else if (imageTablet) {
    imageDefault = imageTablet;
  } else {
    imageDefault = image;
  }
  const imageMedium = imageDefault === imageTablet ? null : imageTablet;
  const imageLarge = imageDefault === image ? null : image;

  const _commonImgProps = {
    alt,
    sizes,
    fill,
    quality: 90,
  };

  const {
    props: { src: srcMedium, srcSet: srcSetMedium },
  } = getImageProps({
    ..._commonImgProps,
    src: imageMedium ?? "",
  });

  const {
    props: { src: srcLarge, srcSet: srcSetLarge },
  } = getImageProps({
    ..._commonImgProps,
    src: imageLarge ?? "",
  });

  return (
    <picture>
      {imageLarge && (
        <source
          media="(min-width: 1024px)"
          src={srcLarge}
          sizes={sizes}
          srcSet={srcSetLarge}
        />
      )}

      {imageMedium && (
        <source
          media="(min-width: 768px)"
          src={srcMedium}
          sizes={sizes}
          srcSet={srcSetMedium}
        />
      )}

      <Image
        alt={alt}
        className={`w-screen h-full object-cover ${className}`}
        src={imageDefault}
        sizes={sizes}
        fill={fill}
        quality={95}
        priority={priority}
        loading={!priority ? "lazy" : "eager"}
      />
    </picture>
  );
};

export default ArtDirectionImage;
