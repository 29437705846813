import MappedComponent from "@/modules/ComponentMap/ComponentMap";
import {
  Root,
  List as PrimitiveList,
  Content as PrimitiveContent,
  Trigger as PrimitiveTrigger,
} from "@radix-ui/react-tabs";
import { ColorsEnum } from "enums/colors";
import { createSlots, Slot } from "../../utils/create-slots";
import SideTab from "./variants/SideTab";
import TopTab from "./variants/TopTab";

const Trigger = ({ children, value, className = "", ...props }) => (
  <PrimitiveTrigger value={value} className={className} {...props}>
    {children}
  </PrimitiveTrigger>
);

const Content = ({ children, value, className = "" }) => (
  <PrimitiveContent value={value} className={className}>
    {children}
  </PrimitiveContent>
);

export const List = ({ children, className = "", ...props }) => (
  <Slot name="trigger">
    <PrimitiveList {...props} className={className}>
      {children}
    </PrimitiveList>
  </Slot>
);

export const PrimitiveRoot = ({ className = "", children, ...props }) => {
  const slots: any = createSlots(["trigger", "content"], children);
  return (
    <Root {...props} className={className}>
      {slots.trigger}
      {children}
    </Root>
  );
};

export const Tabs = ({
  items,
  layout = "0",
}: {
  items: any[];
  layout: "0" | "1";
}) => {
  return getTabVariant(layout, items);
};

export default Object.assign(Tabs, {
  Root: PrimitiveRoot,
  Trigger,
  Content,
  List,
});

const getTabVariant = (type: "0" | "1", components) => {
  switch (type) {
    case "1":
      return <SideTab components={components} />;
    default:
      return <TopTab components={components} />;
  }
};

export const Typo3Tabs = ({ content, layout, appearance }) => {
  const { mid = [1] } = content;
  const { frameClass } = appearance || {};

  return (
    <div className={`${ColorsEnum[frameClass || 0]} lg:py-12 py-14`}>
      <div className="container">
        {getTabVariant(
          layout,
          mid
            .filter(({ type }) => type === "tab")
            .map((component) => ({
              id: component.id,
              title: component.header,
              subheader: component.subheader,
              icon: component.icon,
              content: (
                <>
                  <div className="bg-white">
                    {component.content.map((component) => (
                      <MappedComponent key={component.id} {...component} />
                    ))}
                  </div>
                </>
              ),
            }))
        )}
      </div>
    </div>
  );
};

export type Typo3TabsProps = {
  type: "tab_module";
  content: {
    mid: any[];
  };
};
