import useCurrentAnchor from "hooks/useCurrentAnchor";
import { Button } from "../Button/Button";
import Link from "@/components/Link/Link";
import IconsEnum from "enums/icons";
import { Icon } from "../Icon";

const AnchorNavigation = ({ items = [], button }) => {
  function trimSlashFromURL(url) {
    if (url.includes("/")) {
      url = url.replace("/", "");
    }
    return url;
  }

  const { currentAnchor } = useCurrentAnchor(
    items.map(({ url }) => trimSlashFromURL(url))
  );

  return (
    <div className="bg-white hidden lg:block sticky top-[87px] z-20  ">
      <div className="flex container border-b-2 border-gray-300 bg-white">
        <div className="flex h-full  w-full items-center overflow-x-scroll">
          {items.map(({ url, name }, index) => (
            <Link
              key={`anchor-${index}`}
              href={trimSlashFromURL(url)}
              legacyBehavior
              scroll
            >
              <a
                className="active:border-orange-light whitespace-nowrap hover:border-orange-light active:text-blue-light hover:text-blue-light  active:font-bold border-transparent border-b-4 pb-9 pt-8 mr-7"
                data-state={
                  `#${currentAnchor}` === trimSlashFromURL(url)
                    ? "active"
                    : "inactive"
                }
              >
                {name}
              </a>
            </Link>
          ))}

          {button?.buttonUrl && button?.buttonText && (
            <Link href={button.buttonUrl} passHref legacyBehavior>
              <Button as="a" className="ml-auto cursor-pointer text-center">
                {button.buttonText}
              </Button>
            </Link>
          )}
        </div>
      </div>
    </div>
  );
};

export default AnchorNavigation;

export const VariantFAQ = ({ items = [], content }) => {
  const { currentAnchor } = useCurrentAnchor(items.map(({ url }) => url));
  const { header = "" } = content;

  return (
    <div className="bg-white block  z-20 mb-10">
      <div className="flex container flex-col">
        {header && (
          <h2 className="text-center lg:text-6xl text-4xl mb-7 mt-3">
            {header}
          </h2>
        )}
        <div
          className={`grid lg:grid-cols-${items.length} grid-cols-1 lg:gap-7 gap-6 mx-auto  w-full`}
        >
          {items.map(({ url, name, icon }, index) => (
            <Link key={`anchor-${index}`} href={url} legacyBehavior scroll>
              <a
                className={`rounded-6 flex items-center gap-3 justify-center text-center w-full 
                min-w-full border-blue-light hover:bg-blue-light transition-colors ease-in-out text-blue-light font-bold
                 hover:text-white   border-2  px-6 py-5`}
                data-state={`#${currentAnchor}` === url ? "active" : "inactive"}
              >
                {icon && (
                  <Icon type={IconsEnum[icon]} className="w-[16px] h-[16px]" />
                )}
                {name}
              </a>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};

export const Typo3AnchorNavigation = ({ content, appearance }) => {
  const { item, buttonText, buttonUrl } = content;

  const { layout } = appearance;

  switch (layout) {
    case "layout-1":
      return <VariantFAQ items={item} content={content} />;
    default:
      return (
        <AnchorNavigation items={item} button={{ buttonText, buttonUrl }} />
      );
  }
};
