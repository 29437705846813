import { Root, Title, Description } from "@radix-ui/react-toast";

export const Toast = ({ title, description, variant }) => {
  return (
    <Root className={`${getVariantClass(variant)} `}>
      <div className="container py-5">
        <Title className="font-bold">{title}</Title>
        <Description>{description}</Description>
        {/* <Close>Dismiss</Close> */}
      </div>
    </Root>
  );
};

const getVariantClass = (variant) => {
  switch (variant) {
    case "alert":
      return "bg-alert text-white";
    case "success":
      return "bg-success text-white";
    case "warning":
      return "bg-warning text-white";
    default:
      return "bg-blue-light text-white";
  }
};
