import { getColumnClasses, ViewPorts } from "@/lib/viewportMapper";
import { Typo3Accordion } from "@/components/Accordion/Accordion";
import { Typo3Bullets } from "@/components/Bullets/Bullets";
import { Typo3Gallery } from "@/components/Gallery/Gallery";
import GridRow, { WrapperRow } from "@/modules/Grid/Row";
import { Typo3Headlines } from "@/modules/Headlines/Headlines";
import Separator from "@/components/Separator/Separator";
import { Shortcut } from "@/modules/Shortcut/Shortcut";
import { Typo3Table } from "@/components/Table/Table";
import { Typo3Tabs } from "@/components/Tabs/Tabs";
import Text, { CarHeading } from "@/modules/Text/Text";
import TextImage from "@/modules/TextImage/TextImage";
import { ComponentsMap } from "./types";
import Review from "../Review/Review";
import IconTeaser from "../IconTeaser/IconTeaser";
import TabStage from "../TabStage/TabStage";
import { Typo3Teaser } from "../Teaser/Teaser";
import { Typo3FileList } from "../FileList/FileList";
import { ColorsEnum } from "enums/colors";
import { Typo3CollapsibleContent } from "../CollapsibleContent/CollapsibleContent";
import { Typo3UspBar } from "../UspBar/UspBar";
import { Typo3AnchorNavigation } from "@/components/AnchorNavigation/AnchorNavigation";
import Video from "@/components/Video/Video";
import { Typo3PriceBlocks } from "../PriceBlocks/PriceBlocks";
import dynamic from "next/dynamic";
import LastSeen from "@/modules/LastSeen/LastSeen";
import Stage from "../Stage/Stage";
import LinkList from "../LinkList/LinkList";
import JobImageGallery from "../JobImageGallery/JobImageGallery";
import JobFilter from "../JobFilter/JobFilter";
import Contact from "../Contact/Contact";
import JobTeaser from "../JobTeaser/JobTeaser";
import JobDetail from "../JobDetail/JobDetail";
import JobApplicationTile from "../JobApplicationTile/JobApplicationTile";
import JobNavigation from "../JobNavigation/JobNavigation";
import LinkModule from "../LinkModule/LinkModule";
import JobStage from "../Stage/JobStage";
import SeoText from "@/components/SeoText/SeoText";
import ModelGrid from "../ModelGrid/ModelGrid";
import ModelOverviewGrid from "../ModelOverviewGrid/ModelOverviewGrid";

let componentsMap: ComponentsMap = {
  form_formframework: dynamic(() => import("@/modules/Form/Form")),
  slider: dynamic(() =>
    import("@/components/Slider/Slider").then(({ Typo3Slider }) => Typo3Slider)
  ),
  bracontent_teaser: Typo3Teaser,
  bracontent_staticstage: Stage,
  brarating_review: Review,
  bracontent_linklist: LinkList,
  bracontent_seotext: Text,
  bracontent_seo_text: SeoText,
  bracontent_linklistpic: IconTeaser,
  text: Text,
  bullets: Typo3Bullets,
  textpic: TextImage,
  textmedia: TextImage,
  header: Typo3Headlines,
  image: Typo3Gallery,
  table: Typo3Table,
  html: dynamic(() => import("@/modules/HTML/HTML")),
  div: Separator,
  shortcut: Shortcut,
  accordion: Typo3Accordion,
  tab_module: Typo3Tabs,
  bracar_cardetail: dynamic(() => import("@/modules/CarDetail/CarDetail")),
  bracar_searchextendedfilter: dynamic(() => import("@/modules/Filter")),
  bracar_cartabslider: TabStage,
  bracar_carmodelslider: ModelGrid,
  bracar_carslider: dynamic(() =>
    import("../Product/Product").then(
      ({ Typo3ProductSlider }) => Typo3ProductSlider
    )
  ),
  uploads: Typo3FileList,
  collapsible_content: Typo3CollapsibleContent,
  bracontent_uspbar: Typo3UspBar,
  bracontent_anchornavigation: Typo3AnchorNavigation,
  bracontent_fullwidthvideo: Video,
  bracontent_tooltip: Typo3PriceBlocks,
  CarHeading: CarHeading,
  bracontent_lastseen: LastSeen,
  bracontent_usercentrics: dynamic(
    () => import("@/modules/UCData/DataModule"),
    { ssr: false }
  ),
  bracar_carmodeloverviewgrid: ModelOverviewGrid,
  GridRow,
  "20_wrapper": WrapperRow,
  "25_wrapper": WrapperRow,
  "33_wrapper": WrapperRow,
  "50_wrapper": WrapperRow,
  "100_wrapper": WrapperRow,
  "25_image_wrapper": LinkModule,
  bracontent_image_gallery: JobImageGallery,
  bracareer_joblist: JobFilter,
  bracontent_contact: Contact,
  bracareer_jobteaser: JobTeaser,
  bracareer_jobshow: JobDetail,
  bracontent_application_process: JobApplicationTile,
  bracontent_link_teaser: JobNavigation,
  bracareer_jobstage: JobStage,
};

export const getComponent = (componentKey: string, map: ComponentsMap = {}) => {
  if (!{ ...componentsMap, ...map }[componentKey]) {
    console.error(`Component ${componentKey} doesn't exist.`);
    return false;
  }

  return { ...componentsMap, ...map }[componentKey];
};

interface MappedComponentProps {
  type?: string;
  map?: ComponentsMap;
  [key: string]: any;
}

const MappedComponent = ({
  type,
  map = {},
  ...restProps
}: MappedComponentProps) => {
  if (!type) {
    console.error("Please provide a type property to the MappedComponent");
    return <div>Please provide a type property to the MappedComponent</div>;
  }

  const Component = getComponent(type, map);

  if (Component) {
    const JSXComponent = Component as any;
    return (
      <JSXComponent
        key={restProps.id}
        type={type}
        map={map}
        className={ColorsEnum[restProps.appearance?.frameClass || 0]}
        {...restProps}
      />
    ) as any;
  }

  if (process.env.NODE_ENV !== "production") {
    return (
      <pre
        className={`container ${
          (restProps as any).properties?.gridColumnClassAutoConfiguration
            ?.viewPorts
            ? getColumnClasses(
                (restProps as any).properties?.gridColumnClassAutoConfiguration
                  ?.viewPorts
              )
            : ""
        } border border-indigo-600 my-2 py-2 text-xs`}
      >
        <code>{JSON.stringify({ type, ...restProps }, null, "\t")}</code>
      </pre>
    );
  }

  return null;
};

export default MappedComponent;
