import { useState, useEffect } from "react";
import IconMap from "./IconMap";

const loadIcon = async (iconName: string) => {
  const iconEntry = IconMap[iconName];
  if (!iconEntry) {
    console.error(`Icon "${iconName}" not found in IconMap`);
    return null;
  }

  try {
    if (!iconEntry.module) {
      const iconModule = await import(`./icons/${iconEntry.name}`);
      iconEntry.module = iconModule.default;
    }
    return iconEntry.module;
  } catch (error) {
    console.error(`No IconFile found for Icon "${iconEntry}"`);
    return null;
  }
};

interface IconProps {
  type: string;
  purposeLabel?: string;
  className?: string;
}

export const Icon: React.FC<IconProps> = ({
  type,
  purposeLabel = "",
  className = "",
}) => {
  const [Component, setComponent] = useState<React.FC | null>(
    IconMap[type]?.module ? () => IconMap[type].module : null
  );

  useEffect(() => {
    let isMounted = true;

    if (IconMap[type]?.module) {
      setComponent(() => IconMap[type].module);
    } else {
      loadIcon(type).then((module) => {
        if (isMounted) {
          setComponent(() => module);
        }
      });
    }
    return () => {
      isMounted = false;
    };
  }, [type]);

  return Component ? (
    <Component {...{ className, "aria-label": purposeLabel }} />
  ) : null;
};
