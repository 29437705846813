import { createSlots } from "../../utils/create-slots";
import dynamic from "next/dynamic";
import React from "react";
import useNetworkState from "hooks/useNetworkState";
import { useRouter } from "next/router";
import { useIsomorphicLayoutEffect } from "hooks/useIsomorphicLayoutEffect";

const DynamicUsercentrics: any = dynamic(
  () => import("../Usercentrics/Usercentrics"),
  {
    ssr: false,
  }
);

const Layout = ({
  children,
  type = 2,
  transparentheader = false,
  uc = true,
}) => {
  const router = useRouter();

  const { online: isOnline } = useNetworkState();

  useIsomorphicLayoutEffect(() => {
    if (
      typeof window !== "undefined" &&
      "serviceWorker" in navigator &&
      (window as any).workbox !== undefined &&
      isOnline
    ) {
      // skip index route, because it's already cached under `start-url` caching object
      if (router?.route !== "/") {
        const wb = (window as any).workbox;
        wb.active.then((worker) => {
          wb.messageSW({ action: "CACHE_NEW_ROUTE" });
        });
      }
    }
  }, [isOnline, router?.route]);

  return (
    <>
      {!transparentheader && (
        <div
          className="w-full pt-[85px] xl:pt-[57px] lg:pt-[87px]"
          role="separator"
        ></div>
      )}
      {getLayout(type, children)}
      {uc && <DynamicUsercentrics settingsId={"B83sb5M5"} />}
    </>
  );
};

Layout.displayName = "Layout";

const getLayout = (type: number, children) => {
  const [
    colPos1,
    colPos2,
    colPos0,
    Breadcrumbs,
    Navigation,
    Footer,
    FooterMeta,
  ] = createSlots(
    [
      "colPos1",
      "colPos2",
      "colPos0",
      "Breadcrumbs",
      "Navigation",
      "Footer",
      "FooterMeta",
    ],
    children
  );

  switch (type) {
    case 0:
    case 1:
    case 3:
    case 5:
      // Startseite
      // Unterseite ohne Breadcrumb
      return (
        <>
          {Navigation}
          {colPos0}
          <main className={` lg:z-0 lg:pt-0 pt-8 `}>
            {colPos1}
            {colPos2}
          </main>
          {Footer}
          {FooterMeta}
        </>
      );

    default:
      return (
        <>
          {Navigation}
          {colPos0}
          <main className={`lg:z-0 lg:pt-0 pt-8`}>
            {colPos1}
            {colPos2}
          </main>
          {Footer}
          {FooterMeta}
        </>
      );
  }
};

export default Object.assign(Layout);
