import { Button } from "@/components/Button/Button";
import Tile from "../Tile/Tile";

const JobTeaser = ({ content, id = "" }) => {
  const { data } = content;

  const { headline, jobs, link, linkText } = data;
  return (
    <div className="container lg:pb-10 pb-9">
      <p
        className="text-4xl lg:text-6xl text-center font-bold lg:py-13 py-8"
        id={`c${id}`}
      >
        {headline}
      </p>
      <div className="flex flex-col gap-y-7">
        {jobs.map((tile, index) => {
          return <Tile key={index} type={"career"} content={tile}></Tile>;
        })}
      </div>
      {link && linkText && (
        <Button
          type="button"
          variant="regular"
          as="a"
          href={link}
          color="primary"
          className="w-full lg:w-max mx-auto hover:border-orange-medium group-hover:text-orange-medium mt-8 lg:mt-10"
        >
          {linkText}
        </Button>
      )}
    </div>
  );
};

export default JobTeaser;
