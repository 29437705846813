import { Icon } from "@/components/Icon";
import Headlines from "../Headlines/Headlines";

export const Typo3FileList = ({ content }) => {
  const { media, header, subheader } = content;

  return (
    <div className="container">
      <Headlines {...content} />
      <ul className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 md:gap-x-16 ">
        {media.map(({ publicUrl, properties }, index: number) => (
          <li
            key={`file-${index}`}
            className="flex text-blue-light lg:text-3xl text-base hover:text-blue-medium w-full whitespace-nowrap mb-7 cursor-pointer"
          >
            <Icon
              type="download"
              purposeLabel="Datei download"
              className="w-8 h-8 mr-4"
            />
            <a
              download={properties.title}
              target="_blank"
              href={publicUrl}
              rel="noreferrer"
              className="text-ellipsis overflow-hidden  "
            >
              {properties.title || publicUrl}
            </a>
            <span>&nbsp;({properties.size})</span>
          </li>
        ))}
      </ul>
    </div>
  );
};
