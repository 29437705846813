import Tabs from "@/components/Tabs/Tabs";
import {
  Content as PrimitiveContent,
  Trigger as PrimitiveTrigger,
} from "@radix-ui/react-tabs";
import { useState } from "react";
import Tile from "../Tile/Tile";
import s from "./JobFilter.module.css";

const Trigger = ({ children, value }) => {
  return (
    <PrimitiveTrigger
      value={value}
      className={`group w-auto font-semibold text-blue-light py-5 px-6 bg-white border-2 border-blue-light rounded-6 hover:bg-blue-light active:bg-blue-light flex flex-wrap lg:justify-center items-start hover:text-white active:text-white  transition-all text-start lg:text-center`}
    >
      {children}
    </PrimitiveTrigger>
  );
};

const TopTab = ({ components }) => {
  const [val, setVal] = useState(0);
  const jobsTop = Object.keys(components);
  return (
    <Tabs.Root
      className="relative"
      onValueChange={(e) => {
        setVal(e);
      }}
      value={val}
    >
      <Tabs.List
        className={`${s.TabNavigation} w-full lg:overflow-y-visible overflow-y-scroll `}
      >
        <div className={`flex gap-6 lg:justify-center justify-start `}>
          {jobsTop.map((item, index) => {
            return (
              <Trigger value={index} key={item || index}>
                <span className="whitespace-nowrap">{item}</span>
              </Trigger>
            );
          })}
        </div>
      </Tabs.List>

      {Object.values(components).map((item: any, index) => {
        return (
          <Tabs.Content key={item.category || index} value={index}>
            <div className="w-full mb-10 mt-8">
              <p className="font-bold text-blue-dark text-sm">
                {item.length} Job{item.length > 1 ? "s" : ""} verfügbar
              </p>
            </div>
            <div className="flex flex-col gap-y-7">
              {item.map((tile, index) => {
                return <Tile key={index} type={"career"} content={tile}></Tile>;
              })}
            </div>
          </Tabs.Content>
        );
      })}
    </Tabs.Root>
  );
};

const JobFilter = ({ content }) => {
  const data = { content };
  const headerData = data.content.data.top;
  const JobData = data.content.data.jobs;
  return (
    <>
      <div className="container mt-5 lg:mb-16 mb-9">
        <h1 className="text-center lg:text-7xl text-5xl lg:mt-16 mt-9">
          {headerData.headline}
        </h1>
        <p className="text-center lg:text-5xl text-3xl text-blue-dark lg:mb-16 mb-9">
          {headerData.subHeadline}
        </p>
        <p className="text-center lg:text-5xl text-4xl text-blue-dark my-8 font-bold">
          {headerData.filterText}
        </p>
        <TopTab components={JobData} />
      </div>
    </>
  );
};

export default JobFilter;
