import Breadcrumbs from "@/components/Breadcrumbs/Breadcrumbs";
import { Button } from "@/components/Button/Button";
import { Icon } from "@/components/Icon";
import { PageContext } from "pages/[[...slug]]";
import { Fragment, useContext } from "react";
import HTML from "../HTML/HTML";
import Stage from "../Stage/Stage";
import * as gtag from "@/lib/gtag";

const JobDetail = ({ content }) => {
  const { data } = content;
  const { color = "default" } = data;
  const { breadcrumbs, appearance, meta } = useContext(PageContext);
  const newAsset = [
    {
      publicUrl: data.imageStage,
    },
  ];

  const whiteHeadlineStyleAddition =
    color === "white"
      ? "[&>*>h1]:!text-white [&>*>h1]:drop-shadow-[0_1px_1px_rgba(0,0,0,0.8)]"
      : "";

  const stageContent = {
    bodytext: "",
    buttontext: "",
    header: data.title,
    headerLink: data.url,
    subheader: "",
    assets: newAsset,
    assetsMobile: newAsset,
    scrolltext: "",
    header_layout: 1,
  };

  const addTJobPositingJsonLd = () => {
    return {
      __html: `{
      "@context" : "https://schema.org/",
      "@type" : "JobPosting",
      "title" : "${data.title || "title"}",
      "description" : "${data.seoDescription || "description"}",
      "datePosted" : "${data.datePosted || "datePosted"}",
      "employmentType" : "FULL_TIME",
      "hiringOrganization" : {
        "@type" : "Organization",
        "name" : "Ford Kögler GmbH",
        "sameAs" : "https://www.ford-koegler.de/"
      },
      "jobLocation": {
      "@type": "Place",
        "address": {
        "@type": "PostalAddress",
        "streetAddress": "Feldbergstraße 1-7",
        "addressLocality": "Bad Nauheim",
        "postalCode": "61231",
        "addressCountry": "DE"
        }
      },
      "directApply":true
    }`,
    };
  };

  return (
    <Fragment>
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={addTJobPositingJsonLd()}
      />
      <div className="relative">
        <Breadcrumbs
          items={breadcrumbs}
          className="relative top-12  hidden xl:flex !z-20"
        >
          {breadcrumbs.map(({ link, title }) => (
            <Breadcrumbs.Item href={link} key="link">
              {title}
            </Breadcrumbs.Item>
          ))}
        </Breadcrumbs>
        <Stage
          appearance={appearance}
          content={stageContent}
          className={`!min-h-[70vh] lg:!min-h-[90vh] absolute inset-0 xl:-mt-10  ${whiteHeadlineStyleAddition} `}
        />
      </div>

      <InfoBar {...data} />

      <div className="container w-full h-full lg:border-0 border-t-[1px] border-gray-300 lg:mt-0 mt-4">
        <h2 className="lg:text-6xl text-4xl lg:mt-11 mt-8 lg:mb-9 mb-4">
          {data.subTitle}
        </h2>
        <HTML
          content={data.description}
          className="[&>p]:text-base [&>p]:text-gray-600 [&>h2]:lg:mb-7 [&>h2]:mb-6 [&>h2]:text-4xl"
        />
        <Chapters content={data.chapters} />
      </div>
    </Fragment>
  );
};

const Chapters = ({ content }) => {
  return (
    <div className="lg:my-16 my-9">
      {content.map((item, index) => {
        return (
          <div
            key={index}
            className="flex lg:flex-row flex-col w-full lg:my-16 my-9 "
          >
            <p className="lg:text-4xl text-3xl font-bold xl:1/3 lg:w-1/2 w-full lg:mb-0 mb-6">
              {item.title}
            </p>
            <HTML
              content={item.description}
              className="[&>ul]:list-disc [&>ul]:lg:px-0 [&>ul]:px-[20px] [&>p]:mb-[36px] [&>*]:text-gray-600 [&>*]:text-base xl:w-2/3 lg:w-1/2 w-full "
            />
          </div>
        );
      })}
    </div>
  );
};

const InfoBar = ({ location, duration, entry, formUrl }) => {
  const gtmTracking = () => {
    gtag.event({
      event: "itemClick",
      itemClick: "jetzt_bewerben",
      itemType: "button",
    });
  };
  const handleClick = () => {
    gtmTracking();
  };
  return (
    <div className="bg-white block sticky lg:top-[57px] top-[-75px] z-20">
      <div className="flex container">
        <div className="lg:mb-0 mb-8 mt-8 lg:mt-0 border-b-[1px] border-gray-300 flex lg:flex-row flex-col h-full bg-white w-full lg:items-center whitespace-nowrap">
          <div className="border-transparent border-b-4 lg:pb-9 lg:pt-8 pb-6 mr-10 font-bold text-blue-dark flex items-center text-sm lg:text-2xl">
            <Icon
              type={"location"}
              className="lg:w-8 lg:h-8 w-7 h-7 text-orange-medium mr-4"
            />
            {location}
          </div>
          <div className="border-transparent border-b-4 lg:pb-9 lg:pt-8 pb-6 mr-10 font-bold text-blue-dark flex items-center text-sm lg:text-2xl">
            <Icon
              type={"clock"}
              className="lg:w-8 lg:h-8 w-7 h-7 text-orange-medium mr-4"
            />
            {duration ? duration : "unbefristet"}
          </div>
          <div className="border-transparent border-b-4 lg:pb-9 lg:pt-8 pb-8 mr-10 font-bold text-blue-dark flex items-center text-sm lg:text-2xl">
            <Icon
              type={"calendar"}
              className="lg:w-8 lg:h-8 w-7 h-7 text-orange-medium mr-4"
            />
            {entry}
          </div>

          <Button
            onClick={handleClick}
            as="a"
            className="ml-auto cursor-pointer lg:w-auto w-full"
            href={formUrl}
          >
            Jetzt bewerben
          </Button>
        </div>
      </div>
    </div>
  );
};
export default JobDetail;
