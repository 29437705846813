import ModelCard from "@/modules/ModelCard/ModelCard";

const ModelGrid = ({ content }) => {
  const { data, header = "Häufig gesuchte Fahrzeuge" } = content;
  const { slides } = data;
  const slidesArray = Object.keys(slides).map((key) => slides[key]);

  return (
    <div className=" bg-gray-100 relative  z-0 py-0 lg:pt-8 lg:pb-12">
      <div className="container px-3 ">
        <h2 className="text-center pb-6 pt-6 lg:pb-9 lg:pt-0 text-4xl lg:text-6xl">
          {header}
        </h2>

        <div className="grid lg:grid-cols-3 grid-cols-2 gap-[20px]">
          {slidesArray.map((item, index) => (
            <ModelCard item={item} key={index} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default ModelGrid;
