//@ts-nocheck
import MappedComponent from "@/modules/ComponentMap/ComponentMap";
import { Typo3Headlines } from "@/modules/Headlines/Headlines";
import {
  Splide,
  SplideProps,
  SplideSlide,
  SplideTrack,
} from "@splidejs/react-splide";
import "@splidejs/react-splide/css/core";
import {
  createContext,
  FC,
  forwardRef,
  LegacyRef,
  PropsWithChildren,
  RefObject,
  useContext,
  useRef,
  useState,
} from "react";
import { createSlots } from "utils/create-slots";
import { Icon } from "../Icon";
import s from "./Slider.module.css";
import { cva } from "class-variance-authority";
import { getColPosContainer } from "utils/get-col-pos-container";

const buttonWrapperStyles = cva(
  "splide__arrows absolute w-full flex justify-between h-full items-center pointer-events-none z-30 ",
  {
    variants: {
      position: {
        inside: "overflow-hidden",
        insideGallery: "overflow-hidden",
        outside: "!items-center",
      },
    },
    defaultVariants: {
      position: "outside",
    },
  }
);

const buttonStyles = cva(
  "splide__arrow p-5 rounded-full pointer-events-auto disabled:opacity-30",
  {
    variants: {
      variant: {
        blue: "bg-blue-dark",
        gray: "bg-gray-600",
        bluishGray: "bg-[#4a6171]",
      },
      direction: {
        next: "splide__arrow--next",
        prev: "splide__arrow--prev",
      },
      position: {
        inside: "",
        outside: "",
        insideGallery: "",
      },
    },
    compoundVariants: [
      {
        position: "inside",
        direction: "prev",
        class: "-translate-x-1/2",
      },
      {
        position: "inside",
        direction: "next",
        class: "translate-x-1/2",
      },
      {
        position: "insideGallery",
        direction: "prev",
        class:
          "-translate-x-1/2 [&>svg]:left-4  [&>svg]:xl:left-5 [&>svg]:relative  xl:p-7 p-6  opacity-70 [&>svg]:w-[20px]",
      },
      {
        position: "insideGallery",
        direction: "next",
        class:
          "translate-x-1/2 [&>svg]:right-4  [&>svg]:xl:right-5 [&>svg]:relative  xl:p-7 p-6  opacity-70 [&>svg]:w-[20px]",
      },
      {
        position: "outside",
        direction: "prev",
        class: "opacity-60 lg:opacity-100",
      },
      {
        position: "outside",
        direction: "next",
        class: "opacity-60 lg:opacity-100",
      },
    ],
    defaultVariants: {
      variant: "gray",
      position: "outside",
    },
  }
);

const Arrows: FC<
  PropsWithChildren<{
    position?: "inside" | "insideGallery" | "outside";
    variant?: "blue" | "gray" | "bluishGray";
    className?: string;
  }>
> = ({ variant = "gray", position = "outside", className = "" }) => {
  return (
    <div className={`${className} ${buttonWrapperStyles({ position })}`}>
      <button
        className={buttonStyles({ direction: "prev", variant, position })}
      >
        <Icon
          type="chevron"
          className="w-[25px] rotate-180 text-white"
          purposeLabel="Zum vorherigen Bild"
        />
      </button>
      <button
        className={buttonStyles({ direction: "next", variant, position })}
      >
        <Icon
          type="chevron"
          className="w-[25px] text-white"
          purposeLabel="Zum nächsten Bild"
        />
      </button>
    </div>
  );
};

const Slide = forwardRef(
  (
    { children, className = "" }: PropsWithChildren<{ className?: string }>,
    ref?: LegacyRef<HTMLLIElement>
  ) => {
    return (
      <SplideSlide className={className} ref={ref}>
        {children}
      </SplideSlide>
    );
  }
);

Slide.displayName = "Slide";

const Pagination = () => {
  const tablist = useRef<HTMLUListElement>();
  const { currentPage } = useContext(SliderContext);

  if (tablist?.current?.children) {
    const count = Array.from(tablist?.current?.children).length;
    Array.from(tablist?.current?.children).forEach((child, index) => {
      let pagesBefore = currentPage - 1 > 0 ? 1 : 0;
      let pagesAfter =
        currentPage + 2 > count ? Math.abs(currentPage + 2 - (count + 2)) : 2;

      if (pagesBefore === 0) pagesAfter++;

      if (pagesAfter < 2) pagesBefore = pagesBefore + pagesAfter + 1;

      const isVisible =
        index >= currentPage - pagesBefore && index <= currentPage + pagesAfter;
      if (isVisible) return child.classList.add("!inline-block");
      child.classList.remove("!inline-block");
    });
  }

  return (
    <ul
      className={`splide__pagination ${s.Pagination}`}
      role="tablist"
      aria-label="wähle die Slide"
      ref={tablist as any}
    ></ul>
  );
};

export const SliderContext = createContext<{
  sliderRef?: RefObject<Splide | undefined> | null;
  currentPage: number;
}>({
  sliderRef: undefined,
  currentPage: -1,
});

const Slider: FC<
  PropsWithChildren<
    {
      className?: string;
      trackClassName?: string;
    } & SplideProps
  >
> = ({
  children,
  options = {},
  className = "",
  trackClassName = "",
  ...props
}) => {
  const [arrows, pagination, slot] = createSlots(
    ["arrows", "pagination"],
    children
  );
  const [currentPage, setCurrentPage] = useState(-1);

  const sliderRef = useRef<any>();

  return (
    <>
      <div className="relative">
        <SliderContext.Provider
          value={{
            sliderRef,
            currentPage,
          }}
        >
          <Splide
            onPaginationUpdated={(el, i, prev, curr) => {
              setCurrentPage(curr.page);
            }}
            hasTrack={false}
            className={`${className} w-full flex relative flex-col`}
            ref={sliderRef as any}
            options={{
              ...options,
              keyboard: "focused",
              paginationKeyboard: true,
              pagination: !!pagination?.length,
              omitEnd: true,
            }}
            {...props}
          >
            <SplideTrack className={trackClassName}>{slot}</SplideTrack>
            {arrows || <Arrows />}
            {pagination}
          </Splide>
        </SliderContext.Provider>
      </div>
    </>
  );
};

export const Typo3Slider = ({ colPos, content, className }) => {
  return (
    <div className={`${className} py-15`}>
      <div className={getColPosContainer(colPos)}>
        <Typo3Headlines content={content} />

        <Slider
          aria-label={content.ariaLabel || "Generic Slider"}
          className="flex !visible"
          options={{
            perPage: 3,
            perMove: 1,
            autoHeight: true,
            paginationKeyboard: true,
            direction: "ltr",
            keyboard: "focused",
            pagination: false,
            gap: 7,
            breakpoints: {
              1024: {
                perPage: 2,
              },
              640: {
                perPage: 1,
              },
            },
          }}
        >
          {content.slides.map((item) => (
            <Slide key={item.id}>
              <MappedComponent {...item} />
            </Slide>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default Object.assign(Slider, { Slide, Arrows, Pagination });
