import Link from "@/components/Link/Link";
import React, { Fragment } from "react";
import { Slot } from "utils/create-slots";
import { ComponentProps } from "../../utils/types/ComponentProps";
import { Icon } from "../Icon";
export type BreadcrumbsProps = React.PropsWithChildren<{
  className?: string;
  items: BreadcrumbItemI[];
}>;

interface BreadcrumbItemI {
  link: string;
  title: string;
}

const addBreadcrumbsJsonLd = (items) => {
  return {
    __html: `{
      "@context": "https://schema.org",
      "@type": "BreadcrumbList",
      "itemListElement": [
        ${items
          .map(
            ({ title, link }, index: number) => `{
          "@type": "ListItem",
          "position": ${index + 1},
          "name": "${title}",
          "item": "${process.env.NEXT_PUBLIC_FRONTEND_URL}${link}"
        }`
          )
          .join(",")}
      ]
    }`,
  };
};

function Breadcrumbs({
  children,
  items,
  className = "",
}: React.PropsWithChildren<BreadcrumbsProps>) {
  const wrappedChildren = React.Children.map(children, (child, index) => (
    <Fragment key={index}>
      <li className="text-gray-600 hover:text-blue-light last:text-blue-light text-sm group flex items-center whitespace-nowrap">
        {child}
        <Icon
          type="chevron"
          className="group-last:hidden w-[12px] mx-2 text-orange-light"
        />
      </li>
    </Fragment>
  ));
  return (
    <Slot name="Breadcrumbs">
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={addBreadcrumbsJsonLd(items)}
      />
      <nav
        className={`container pt-7 z-10 ${className}`}
        aria-label="Breadcrumbs"
      >
        <ol className="list-reset flex no-scrollbar overflow-x-auto xl:overflow-visible">
          {wrappedChildren}
        </ol>
      </nav>
    </Slot>
  );
}

const BreadcrumbsItem = ({ href, children }) => (
  <Link href={href}>{children}</Link>
);
Breadcrumbs.displayName = "Breadcrumbs";

BreadcrumbsItem.displayName = "Breadcrumbs.Item";

export type BreadcrumbsItemProps = ComponentProps<typeof BreadcrumbsItem>;
export default Object.assign(Breadcrumbs, { Item: BreadcrumbsItem });
