// TODO: get layout names from be

import Breadcrumbs from "@/components/Breadcrumbs/Breadcrumbs";
import { Button } from "@/components/Button/Button";
import { Icon } from "@/components/Icon";
import useIntersectionObserver from "hooks/useIntersectionObserver";
import Link from "@/components/Link/Link";
import { PageContext } from "pages/[[...slug]]";
import { FC, Fragment, PropsWithChildren, useContext, useRef } from "react";
import Headlines from "../Headlines/Headlines";
import HTML from "../HTML/HTML";
import Image from "next/image";
import { getTextColorClass } from "@/lib/getTextColorClass";
import ArtDirectionImage from "@/components/Image/ArtDirectionImage";
import { cva } from "class-variance-authority";

interface HeaderLinkI {
  href: string;
}

interface ContentI {
  bodytext: string;
  buttontext: string;
  header: string;
  headerLink?: string;
  subheader: string;
  assets: any[];
  assetsMobile: any[];
  scrolltext?: string;
  textColor?: "white" | "blue";
  header_layout: any;
  showBadgeFlag?: boolean;
}

const StageGeneralStyles = cva(
  " w-full ] min-h-[400px]  relative flex flex-col justify-center lg:items-center items-end p-6 lg:p-0",
  {
    variants: {
      layout: {
        default: " xl:min-h-screen lg:min-h-[60vh]",
        "layout-1":
          " 2xl:min-h-[80vh] xl:min-h-[80vh] lg:min-h-[60vh] min-h-[70vh]",
      },
    },
    defaultVariants: {
      layout: "default",
    },
  }
);
const StageHeadlineStyles = cva(
  "container text-center  h-full lg:w-full  z-10 mb-auto pt-11 ",
  {
    variants: {
      layout: {
        default: "  mt-[5%]",
        "layout-1":
          "2xl:mt-[8%] hd:mt-[5%] xl:mt-[6%] xlmac:mt-8 lg:mt-[6vh] mt-13 !w-full",
      },
    },
    defaultVariants: {
      layout: "default",
    },
  }
);

const Stage: FC<
  PropsWithChildren<{
    className?: string;
    content: ContentI;
    appearance?: any;
    id?: string;
  }>
> = ({
  children,
  className = "",
  content,
  id = "",
  appearance: topLevelAppearence = [],
}) => {
  const {
    bodytext,
    buttontext,
    header,
    headerLink,
    subheader,
    assets,
    assetsMobile,
    textColor = "blue",
    scrolltext,
    header_layout,
    showBadgeFlag = false,
  } = content;

  const { breadcrumbs, appearance } = useContext(PageContext);
  const { layout, transparentheader } = appearance;
  const hasBreadcrumbs = ![0, 1, 3, 5].includes(layout);
  const buttonRef = useRef<HTMLButtonElement | null>(null);
  const stageRef = useRef<HTMLDivElement | null>(null);
  const entry = useIntersectionObserver(buttonRef, {
    threshold: 1,
  });
  const isVisible = !!entry?.isIntersecting;

  return (
    <Fragment>
      {buttontext && headerLink && !isVisible && (
        <div
          className={`w-full fixed  md:hidden bottom-0 z-50  animate-ascend flex items-center h-[95px] `}
        >
          <div
            className={`container flex md:justify-end justify-center`}
          >
            <Link href={headerLink} passHref legacyBehavior>
              <Button size="medium" className={`w-full md:w-auto`} as="a">
                {buttontext}
              </Button>
            </Link>
          </div>
        </div>
      )}
      <div
        id={`c${id}`}
        ref={stageRef}
        className={` ${StageGeneralStyles({
          layout: topLevelAppearence?.layout || "default",
        })}  ${className} select-none`}
      >
        {assets?.[0].publicUrl && (
          <div className="absolute inset-0 z-0">
            {assetsMobile && assetsMobile[0].publicUrl ? (
              <ArtDirectionImage
                image={assets[0].publicUrl}
                imageMobile={assetsMobile[0].publicUrl}
                alt="image"
                priority={true}
                sizes="(max-width: 768px) 100vw, 100vw"
              />
            ) : (
              <HeroImage bgImage={assets[0].publicUrl} />
            )}
          </div>
        )}
        {showBadgeFlag && <InterestBadge className={""} />}

        {hasBreadcrumbs && breadcrumbs && (
          <Breadcrumbs
            items={breadcrumbs}
            className={transparentheader ? "mt-11" : ""}
          >
            {breadcrumbs.map(({ link, title }) => (
              <Breadcrumbs.Item href={link} key="link">
                {title}
              </Breadcrumbs.Item>
            ))}
          </Breadcrumbs>
        )}
        <div
          className={` ${StageHeadlineStyles({
            layout: topLevelAppearence?.layout || "default",
          })}  `}
        >
          <Headlines
            subheader={subheader}
            header={header}
            headerLayout={header_layout}
            headerPosition={0}
            textColor={textColor}
          />
          {bodytext && (
            <HTML
              className={`lg:text-5xl text-sm mb-6 ${getTextColorClass(
                textColor
              )}`}
              content={bodytext}
            />
          )}

          {children}
          <div className="absolute bottom-9 left-1/2 -translate-x-1/2 container w-full">
            {buttontext && headerLink && (
              <Link href={headerLink} passHref legacyBehavior>
                <Button
                  size="medium"
                  className="mx-auto sm:!inline-flex w-full sm:w-auto"
                  as="a"
                  ref={buttonRef}
                >
                  {buttontext}
                </Button>
              </Link>
            )}
            {scrolltext && (
              <ScrollToTrigger scrollToElement={stageRef}>
                {scrolltext}
              </ScrollToTrigger>
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const HeroImage = ({ bgImage }) => {
  return (
    <Image
      className="w-screen h-full object-cover"
      src={bgImage}
      alt="image"
      fill
      sizes="(max-width: 768px) 100vw, 50vw"
      priority={true}
      loading="eager"
      quality={95}
    />
  );
};
const ScrollToTrigger = ({ children, scrollToElement }) => {
  const handleClick = (scrollToElement) => {
    if (!scrollToElement) return;
    const element = scrollToElement?.current?.nextSibling;
    if (!element) return;

    element.scrollIntoView({
      block: "start",
      inline: "nearest",
      behavior: "smooth",
    });
  };
  return (
    <div
      className="flex justify-center items-center hover:text-blue-medium cursor-pointer group mt-8 "
      onClick={() => handleClick(scrollToElement)}
    >
      <p className="hidden md:block text-base">{children}</p>
      <Icon
        type="chevron"
        className="md:w-6 m:h-6 w-7 h-7 rotate-90 ml-4 group-hover:translate-y-3"
      />
    </div>
  );
};

const InterestBadge = ({ className }) => {
  return (
    <div className=" w-full h-full flex absolute 2xl:bottom-[0%] lg:bottom-[-10%]  md:bottom-[-20%] bottom-[-32%] 2xl:right-[60px] lg:right-[30px]">
      <div
        className={`${className} z-50 flex justify-end items-center w-full h-full`}
      >
        <Icon
          type="interestBadge"
          className="2xl:w-[215px] lg:w-[150px] md:w-[100px] w-[113px] 2xl:h-[215px] lg:h-[150px] md:h-[100px] h-[113px] "
        />
      </div>
    </div>
  );
};

Stage.displayName = "Stage";

export default Object.assign(Stage, {
  HeroImage,
  ScrollToTrigger,
});
