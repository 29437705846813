import "@/styles/globals.css";
import { useRouter } from "next/router";
import ProgressBar from "@badrap/bar-of-progress";
import type { ReactElement, ReactNode } from "react";
import type { NextPage } from "next";
import type { AppProps } from "next/app";
import { ToastProvider } from "@/components/Toast/ToastProvider";
import * as gtag from "@/lib/gtag";
import WishlistProvider from "@/modules/Wishlist/WishlistProvider";
import { Inter } from "next/font/google";
import { SalesforceProvider } from "hooks/useSalesforce";
import UsercentricsProvider from "@/components/Usercentrics/UsercentricsProvider";
import { SpeedInsights } from "@vercel/speed-insights/next";
import { useIsomorphicLayoutEffect } from "hooks/useIsomorphicLayoutEffect";
import { Analytics } from "@vercel/analytics/react";
import Script from "next/script";

const inter = Inter({
  subsets: ["latin"],
  display: "swap",
});

const progress = new ProgressBar({
  size: 2,
  color: "#ff7d00",
  className: "bar-of-progress",
  delay: 100,
});
export type NextPageWithLayout<T> = NextPage<T> & {
  getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout<any>;
};

export default function MyApp({ Component, pageProps }: AppPropsWithLayout) {
  const router = useRouter();
  const baseUrl = process.env.NEXT_PUBLIC_FRONTEND_URL;

  useIsomorphicLayoutEffect(() => {
    const handleRouteChange = (url) => {
      gtag.pageview(url);
      if (!pageProps?.pageProps?.meta?.title || !pageProps.pageProps.slug)
        return;

      gtag.event({
        event: "virtualPageView",
        pageTitle: `${pageProps.pageProps.meta.title}`,
        pageURL: `${baseUrl}${pageProps.pageProps.slug}`,
        page: `${pageProps.pageProps.slug}`,
      });
    };

    router.events.on("routeChangeStart", progress.start);
    router.events.on("routeChangeComplete", progress.finish);
    router.events.on("routeChangeComplete", handleRouteChange);
    router.events.on("routeChangeError", progress.finish);
    router.events.on("hashChangeComplete", handleRouteChange);

    return () => {
      router.events.off("routeChangeStart", progress.start);
      router.events.off("routeChangeComplete", progress.finish);
      router.events.off("routeChangeComplete", handleRouteChange);
      router.events.off("routeChangeError", progress.finish);
      router.events.off("hashChangeComplete", handleRouteChange);
    };
  }, [router, baseUrl, pageProps]);

  const getLayout = Component.getLayout || ((page) => page);

  const JSXComponent = Component as any;

  return getLayout(
    <>
      <link rel="preconnect" href={process.env.NEXT_PUBLIC_API_URL} />
      <link rel="preconnect" href="//app.usercentrics.eu" />
      <link rel="preconnect" href="//api.usercentrics.eu" />
      <link rel="preconnect" href="//aggregator.service.usercentrics.eu" />
      <link rel="preconnect" href="//graphql.usercentrics.eu" />
      <link
        rel="preconnect"
        href="https://consent-api.service.consent.usercentrics.eu"
      />
      {/* eslint-disable-next-line @next/next/next-script-for-ga */}
      <Script
        id="gtm-script"
        type="text/plain"
        data-usercentrics="Google Tag Manager"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                })(window,document,'script','dataLayer','${process.env.NEXT_PUBLIC_GA_ID}');`,
        }}
      />

      <Script
        id="google-tag-manager"
        type="text/plain"
        strategy="afterInteractive"
        data-usercentrics="Google Tag Manager"
        dangerouslySetInnerHTML={{
          __html: `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', '${process.env.NEXT_PUBLIC_GA_ID}', {
        page_path: window.location.pathname,
      });
    `,
        }}
      />

      <Script
        id="salesforce"
        type="text/plain"
        strategy="afterInteractive"
        src="https://510005835.collect.igodigital.com/collect.js"
        data-usercentrics="Salesforce Marketing Cloud"
        defer
      />

      <style jsx global>{`
        html {
          font-family: ${inter.style.fontFamily};
        }
      `}</style>
      <UsercentricsProvider>
        <SalesforceProvider
          orgId={`${process.env.NEXT_PUBLIC_SALESFORCE_ORG_ID}`}
        >
          <ToastProvider>
            <WishlistProvider>
              <JSXComponent {...pageProps} />
              <SpeedInsights sampleRate={0.5} />
              <Analytics />
            </WishlistProvider>
          </ToastProvider>
        </SalesforceProvider>
      </UsercentricsProvider>
    </>
  );
}
