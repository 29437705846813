import { Button } from "@/components/Button/Button";
import { getTextColorClass } from "@/lib/getTextColorClass";
import * as Collapsible from "@radix-ui/react-collapsible";
import Image from "next/image";
import { useState } from "react";
import MappedComponent from "../ComponentMap/ComponentMap";

const CloseButton = (props) => {
  return (
    <div
      {...props}
      className="text-white py-5 px-7 bg-gray-600/40 rounded-full"
    >
      Schließen ✕
    </div>
  );
};

const OpenCloseIcon = ({ open = false, className = "" }) => (
  <div
    className={`h-[16px] w-[16px] my-auto flex justify-center items-center self-end ${className}`}
  >
    <div className="absolute w-[16px] h-[2px] bg-orange-light  transition"></div>
    <div
      className={`absolute w-[16px] h-[2px] bg-orange-light  transition ${
        open ? "rotate-0" : "rotate-90"
      }`}
    ></div>
  </div>
);

const CollapsibleContent = ({
  header,
  subheader,
  bodytext,
  image,
  children,
  textColor = "blue",
  open: initialOpen = false,
  id = "",
}) => {
  const [open, setOpen] = useState(initialOpen);
  return (
    <Collapsible.Root className={`relative`} open={open} onOpenChange={setOpen}>
      <div
        className="relative w-screen h-[250px] lg:h-[740px] left-1/2 -translate-x-1/2">
        <Image src={image} alt={header} fill className="object-cover" />
      </div>
      <div className="container grid grid-cols-1 lg:grid-cols-2 gap-6 py-10" id={`c${id}`}>
        <div>
          {subheader && (
            <p
              className={`text-3xl md:text-4xl lg:text-5xl ${getTextColorClass(
                textColor
              )}`}
            >
              {subheader}
            </p>
          )}
          {header && (
            <p
           
              className={`font-bold text-4xl md:text-5xl xl:text-6xl ${getTextColorClass(
                textColor
              )}`}
            >
              {header}
            </p>
          )}
          <Collapsible.Trigger asChild>
            <Button
              variant="hollow"
              className="w-auto !hidden lg:!flex mt-9 !min-w-[220px]"
            >
              {!open ? "Mehr anzeigen" : "Weniger anzeigen"}
              <OpenCloseIcon open={open} className="ml-4" />
            </Button>
          </Collapsible.Trigger>
        </div>
        <div
          className="text-gray-600"
          dangerouslySetInnerHTML={{ __html: bodytext }}
        />
        <Collapsible.Trigger asChild>
          <Button variant="hollow" className="w-full lg:!hidden mt-7 ">
            {!open ? "Mehr anzeigen" : "Weniger anzeigen"}
            <OpenCloseIcon open={open} className="ml-4" />
          </Button>
        </Collapsible.Trigger>
      </div>
      <Collapsible.Content
        forceMount
        className="max-h-0 open:max-h-[9999px] overflow-hidden transition-all w-screen relative bg-gray-100 left-1/2 -translate-x-1/2 "
      >
        <div className="container pb-8 pt-7">
          {children}
          <div className="flex justify-center mt-8 lg:mt-11">
            <Collapsible.Trigger>
              <CloseButton />
            </Collapsible.Trigger>
          </div>
        </div>
      </Collapsible.Content>
    </Collapsible.Root>
  );
};

export default CollapsibleContent;

export const Typo3CollapsibleContent = ({ id = "", content }) => {
  const {
    header,
    subheader,
    bodytext,
    image,
    textColor = "blue",
    open,
  } = content;

  return (
    <CollapsibleContent
      header={header}
      subheader={subheader}
      bodytext={bodytext}
      textColor={textColor}
      image={image?.[0]?.publicUrl}
      open={open}
      id={id}
    >
      {content.content.map((item) => (
        <MappedComponent key={item.id} {...item} />
      ))}
    </CollapsibleContent>
  );
};
