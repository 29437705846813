import CDNImage from "@/components/Image/CDNImage";
import Image from "@/components/Image/Image";
import Slider from "@/components/Slider/Slider";
import { Fragment } from "react";
import { Slot } from "utils/create-slots";

const JobImageGallery = ({ content }) => {
  const { header, subheader, text, image } = content;
  return (
    <Fragment>
      <div className="container lg:mt-16 mt-8">
        {header && (
          <p className="lg:text-6xl text-4xl font-bold text-blue-dark">
            {header}
          </p>
        )}
        {subheader && (
          <p className="lg:text-4xl text-3xl text-blue-dark font-bold lg:pt-8 pt-5 lg:pb-6 pb-4">
            {subheader}
          </p>
        )}
        {text && <p className="text-gray-600">{text}</p>}

        <div className="grid-cols-3  space-y-2  lg:space-y-0 lg:grid lg:gap-7 lg:grid-rows-3 lg:mt-16 mt-9 hidden">
          {image &&
            image.slice(0, 3).map((item, index) => {
              return (
                <div
                  key={index}
                  className={`w-full rounded"
                `}
                >
                  <Image
                    className=" overflow-hidden"
                    src={item.publicUrl}
                    alt={
                      item.properties.title ||
                      item.properties.alternative ||
                      `Bild Nr.${index + 1}`
                    }
                    objectFit="cover"
                    width={1000}
                    height={600}
                  />
                </div>
              );
            })}
          {image &&
            image.slice(3, image.length).map((item, index) => {
              return (
                <div
                  key={index}
                  className={`${
                    index % 6 === 0 ? "col-span-2 row-span-2" : ""
                  } hw-full rounded"
                `}
                >
                  <Image
                    className=" overflow-hidden"
                    src={item.publicUrl}
                    alt={
                      item.properties.title ||
                      item.properties.alternative ||
                      `Bild Nr.${index + 1}`
                    }
                    objectFit="cover"
                    width={1000}
                    height={`${index % 6 === 0 ? 620 : 600}`}
                  />
                </div>
              );
            })}
        </div>
      </div>
      <Slider
        aria-label="Stage Slider"
        className="lg:hidden flex pt-9"
        options={{
          perPage: 1,
          perMove: 1,
          gap: "2px",
          pagination: false,
        }}
      >
        {image &&
          image.map((item, index) => (
            <Slider.Slide key={index}>
              <Image
                className=" overflow-hidden"
                src={item.publicUrl}
                alt={
                  item.properties.title ||
                  item.properties.alternative ||
                  `Bild Nr.${index + 1}`
                }
                objectFit="cover"
                width={1000}
                height={800}
              />
            </Slider.Slide>
          ))}
        <Slot name="arrows">
          <Slider.Arrows variant="blue" position="insideGallery" />
        </Slot>
      </Slider>
    </Fragment>
  );
};

export default JobImageGallery;
