import Headlines from "@/modules/Headlines/Headlines";

function getPaddingX(index, totalAmount) {
  const isFirstCol = index === 0;
  const isLastCol = index === totalAmount - 1;
  const isSingleCol = isFirstCol && isLastCol;

  // first and last col should not get an outwards padding
  let paddingX: string;
  if (isSingleCol) {
    paddingX = "";
  } else if (isFirstCol) {
    paddingX = "pr-4";
  } else if (isLastCol) {
    paddingX = "pl-4";
  } else {
    paddingX = "px-4";
  }

  return paddingX;
}

const Table = ({ header, subheader, head, body, id = "" }) => {
  return (
    <div className="container mx-auto py-8 lg:py-10" id={`c${id}`}>
      {(header || subheader) && (
        <Headlines header={header} subheader={subheader} />
      )}
      <div className="overflow-x-auto">
        <table className="border-collapse w-full">
          <thead>
            <tr>
              {head?.map((col, index) => (
                <th
                  className={`md:text-2xl text-xl text-left align-bottom pb-5 pt-7 ${getPaddingX(
                    index,
                    head.length
                  )}`}
                  key={index}
                >
                  {col}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {body?.map((cols, index) => (
              <tr
                key={`tbody-row-${index}`}
                className="border-t-[1px] border-gray-300 first:border-transparent"
              >
                {cols.map((col, index) => (
                  <td
                    className={`md:text-base text-sm text-gray-600 first:font-bold py-4 ${getPaddingX(
                      index,
                      cols.length
                    )} md:min-w-0 min-w-[150px] `}
                    key={index}
                  >
                    {col}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export const Typo3Table = ({ content, id }) => {
  const { header, subheader, bodytext } = content;
  console.log("contay", id);
  const [tableHead, ...tableBody] = bodytext;
  return (
    <Table
      header={header}
      subheader={subheader}
      head={tableHead}
      body={tableBody}
      id={id}
    />
  );
};

export default Table;
