import { Icon } from "@/components/Icon";
import Link from "@/components/Link/Link";

const LinkList = ({ content }) => {
  const { items, header } = content;
  const colCount = items.length > 4 ? 4 : items.length;
  return (
    <div className={`container xl:py-8 lg:py-6 py-4`}>
      <h2 className="text-blue-dark xl:text-6xl text-5xl font-bold text-center py-2 xl:py-8 lg:py-6">
        {header}
      </h2>
      <div
        className={`lg:grid-cols-${colCount} grid-cols-2 grid text-center  py-6 gap-x-5`}
      >
        {items.map((item, index) => (
          <Link
            href={item.link}
            key={item.title + index}
            className="flex items-center hover:text-blue-light pb-3  group w-fit border-b-[1px] border-transparent hover:border-blue-light"
          >
            <span>{item.title}</span>
            <Icon
              type="chevron"
              className="w-5 h-5 ml-3  text-blue-medium group-hover:translate-x-3 group-hover:text-blue-light"
            />
          </Link>
        ))}
      </div>
    </div>
  );
};
export default Object.assign(LinkList, {});
